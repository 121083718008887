import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, keywords, image, ogUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            baseUrl
            image
            ogUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords
  const metaImageUrl = image || site.siteMetadata.image
  const metaOgUrl = ogUrl || site.siteMetadata.ogUrl
  const baseUrl = site.siteMetadata.baseUrl
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `og:site_name`,
          content: `Rishaba`,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:image`,
          content: `${baseUrl}${metaImageUrl}`,
        },
        {
          name: `twitter:image`,
          content: `${baseUrl}${metaImageUrl}`,
        },
        {
          name: `image`,
          content: `${baseUrl}${metaImageUrl}`,
        },
        {
          property: `og:url`,
          content: `${baseUrl}${metaOgUrl}`,
        },
        {
          name: `twitter:url`,
          content: `${baseUrl}${metaOgUrl}`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  keywords: [],
  baseUrl: ``,
  image: ``,
  ogUrl: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.array,
  baseUrl: PropTypes.string,
  image: PropTypes.string,
  ogUrl: PropTypes.string
}

export default SEO
